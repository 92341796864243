<template>
  <div class="mt-12" v-if="items.length > 0">
    <div
      class="w-full grid grid-cols-12 gap-4 mb-4 py-4 "
      v-for="(item, i) in items"
      :key="i"
    >
      <div class="col-span-3">
        <img
          :src="item.product_photo"
          class="w-full h-40 rounded-md object-cover"
        />
      </div>
      <div class="col-span-7 flex flex-col">
        <h1 class="text-xl capitalize">{{ item.product_name }}</h1>
        <p class="flex-grow" v-html="item.product_description"></p>
        <span
          >{{ item.quantity }} x
          <b>{{ item.single_unit_price | price }}</b></span
        >
      </div>
      <div class="col-span-2">
        <q-step-number class="mb-4" v-model="item.quantity"></q-step-number>
        <button
          @click="REMOVE_ITEM(item.product_id)"
          class="col-span-3 rounded-md bg-red-300 py-1 w-full"
        >
          <i class="mdi mdi-close"></i> Elimina
        </button>
      </div>
    </div>
    <hr />
    <div class="flex py-8">
      <q-button :cta="true" @click="send" :loading="isLoading"
        >Invia Ordine</q-button
      >
      <h2 class="flex-grow  text-3xl font-bold text-right">
        Total: {{ cartTotal }}
      </h2>
    </div>

    <div class="h-32"></div>
  </div>
  <div class="mt-12 flex flex-col justify-center items-center" v-else>
    <h1 class="text-3xl font-bold text-center">
      il Carrello è vuoto.<br />
      Torna a allo shopping
    </h1>
    <router-link class="btn btn-cta mt-12" to="/">Home</router-link>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      isLoading: false
    };
  },
  computed: {
    ...mapGetters("cart", ["items", "cartTotal"])
  },
  methods: {
    ...mapMutations("cart", ["REMOVE_ITEM"]),
    send() {
      console.log("Invio l'ordine");
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        this.$emit("nextStep");
      }, 500);
    }
  }
};
</script>

<style></style>
